import React from "react"
import SEO from "@components/seo"
import { Link } from "gatsby"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import img from "@components/page404/images/img404.svg"
import data from "@components/pageHome/data"
import TitleUnderline from "@components/page404/titleUnderline"

const NotFoundPage = ({ location }) => (
  <div>
    <SEO title="404" />
    <section className="container">
      <Header path="/" location={location} />
      <img
        className="img404"
        src={img}
        alt="404" />
      <p className="title-404">
        <TitleUnderline underline={'¡Oooops!'} /> Página no encontrada</p>
      <p className="text-404">Es posible que haya escrito mal la URL o que la página se haya eliminado.</p>
      <p style={{ textAlign: 'center' }}>
        <Link className="btn-404" to={`/${location.search}`} >
          Ir a Inicio
        </Link>
      </p>
      <Footer data={data.footer} location={location} />
      <FooterMobile data={data.footer} location={location} />
    </section>
  </div>
)

export default NotFoundPage
